.linkNext {
  display: inline-block;
  color: var(--link-color);
  width: 100%;
  position: relative;
}

.linkNext::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: var(--border-radius-sm);

  pointer-events: none;
}

.linkNext:focus-visible::before {
  outline: 2px solid var(--color-ocean);
}

.shrink {
  width: auto;
}

.showPseudoClasses {
  border-radius: var(--border-radius-sm);
}

.showPseudoClasses:hover {
  background-color: var(--color-mine-shaft-hover);
}

.showPseudoClasses:active {
  background-color: var(--color-mine-shaft-active);
}
