.miniTeaserBlock {
  flex: 1 1 100%;
  padding: var(--spacing-7) 0;
  background-color: var(--mini-teaser-block-bg-color);
}

@media (min-width: 768px) {
  .miniTeaserBlockInner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .miniTeaserBlock {
    padding: var(--spacing-9) 0;
  }
}

@media (min-width: 1148px) {
  .miniTeaserBlockInner {
    justify-content: center;
    max-width: calc(360px * 3);
  }

  .miniTeaserBlockInner.justifyStart {
    justify-content: flex-start;
  }
}

@media (min-width: 1480px) {
  .miniTeaserBlockInner {
    max-width: var(--content-width-lg);
  }
}
