.background {
  background-color: var(--color-white);
  display: grid;
  width: 100%;
}

.richTextWrapper {
  isolation: isolate;
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);
}

.toggleHeader {
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, auto) minmax(0, min-content);
  gap: var(--spacing-05);
  cursor: pointer;
  justify-self: center;
}

.animatedRichTextBody {
  display: grid;
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);

  justify-content: center;

  grid-template-rows: min-content min-content;
  grid-row-gap: var(--spacing-4);
  grid-column-gap: calc(var(--spacing-1) * 10);
  align-items: center;
  align-content: center;
}

.singleColumnBody {
  max-width: calc(2 * var(--grid-margins-xs) + var(--content-width-sm));
  margin: 0 auto;
  width: 100%;
}

.styledHeaderWithIngress {
  max-width: 100%;
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapperMobileBottom {
  order: 2;
}

@media (min-width: 768px) {
  .toggleHeader {
    width: calc(2 * var(--grid-margins-xs) + var(--content-width-sm));
  }

  .styledHeaderWithIngress {
    max-width: calc(2 * var(--grid-margins-xs) + var(--content-width-sm));
  }

  .styledHeaderWithIngress h1,
  .styledHeaderWithIngress h2,
  .styledHeaderWithIngress span {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .richTextWrapper {
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);
  }

  .animatedRichTextBody {
    padding-top: var(--spacing-4);
    padding-bottom: var(--spacing-4);

    justify-content: start;

    grid-template-rows: auto;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .singleColumnBody {
    grid-template-columns: minmax(0, 1fr);
  }

  .justifyStart {
    justify-content: start;
  }

  .imageWrapperRight {
    justify-content: flex-start;
  }

  .imageWrapperLeft {
    justify-content: flex-end;
  }

  .imageWrapper {
    align-items: flex-start;
  }

  .imageWrapperMobileBottom {
    order: unset;
  }
}
