.teaser {
  list-style: none;
  margin-bottom: var(--spacing-2);
  position: relative;
}

@media (min-width: 768px) {
  .teaser {
    width: 50%;
    padding: 0 var(--spacing-1);
  }
}

@media (min-width: 1148px) {
  .teaser {
    width: 33.33%;
  }
}

@media (min-width: 1480px) {
  .teaser {
    width: 25%;
  }
}

.teaserLink {
  display: flex;
  justify-content: space-between;
  min-height: 114px;
  align-items: center;
  padding: var(--spacing-1);
  border-radius: var(--border-radius-md);
  transition: box-shadow 0.3s ease-in-out;

  background-color: var(--mini-teaser-link-bg-color);
  color: var(--mini-teaser-link-text-color);
}

.teaserLink:hover {
  box-shadow: var(--shadow-menu);
}

.teaserLink:focus-visible {
  outline: 2px solid var(--color-ocean);
}

.teaserTextWrapper {
  margin-left: var(--spacing-25);
  margin-right: auto;
}
